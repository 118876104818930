/**
 * Ports of various styles from old WordPress site for compatibility with existing classes in imported posts
 */

.alignleft,
img.alignleft {
	float: left;
    margin-right:20px;
}
.alignright,
img.alignright {
	display: block;
	float: right;
    margin-left:20px;
}
.aligncenter,
img.aligncenter {
	margin-left:auto;
	margin-right:auto;
	display: block;
	clear: both;
}

img.gallery_thumb {
    object-fit: cover;
    margin-bottom: 5px;
}

.responsive_video {
    width: 100%;
}

.youtube_player {
    margin-top: 5px;
    position: relative;
    overflow: hidden;
    padding-top: 50%;
    max-width: 540px;
    max-height: 405px;
}

.youtube_iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.site-nav { 
    z-index: 100;
}

.page-content {
    padding: 40px 0;
}

#content {
    /* margin:0 0 0 10px; */
    max-width:540px;
    margin-left: auto;
    margin-right: auto;
    overflow: visible;
}

$portfolio_thumb_item_width: 150px;

.portfolio_thumb_section {
     display: grid;
     grid-template-columns: repeat(auto-fit, minmax($portfolio_thumb_item_width, max-content));
     grid-column-gap: 30px;
     justify-content: center;
}

.portfolio_thumb_item {
    max-width: $portfolio_thumb_item_width;
    position: relative;
}

#category_picker {
    position: absolute;
    left: 0;
    top: -30px;
}

.invisible {
    display: none;
}

.portfolio_index_date {
    font-size: 10pt;
    color: gray;
}

.entry-content {
    padding: 22px 0 0 0;
}

.entry-utility {
    clear: both;
    color: #666;
    font-size: 13px;
    line-height: 18px;
    margin: 0 0 44px 0;
}

h3.go_link {
    font-size: 150%;
    margin-top: 20px;
}

table.project_info_box {
    border: none;
    border-collapse: collapse;
    margin: 0 0 22px 0;

    tr {
        border-bottom: 1px solid #ccc;
    }

    th, td {
        padding: .7em 1.25em;
    }
}

.icon {
    svg {
        max-width: 16px;
        max-height: 16px; 
    }
}

.footer-col-2 {
    width: calc(50% - (30px / 2));
}

.social-media-list li {
    // background-color: green;
}

.icon {
    // background-color: blue;
}

.icon svg {
    // background-color: red;
}

.header-image {
    padding-bottom: 15px;
}